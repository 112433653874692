<template>
    <div class="private-layout">
        <div class="private-layout__controls">
            <MapControls />
        </div>

        <ax-map class="private-layout__map" />

        <DemoMessage v-if="isDemoUser" class="private-layout__panel" />

        <SidePanel class="private-layout__panel" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import AxMap from '@/components/Map.vue'
import DemoMessage from '@/components/DemoMessage.vue'
import MapControls from '@/components/redesigned/MapControls.vue'
import SidePanel from '@/components/SidePanel.vue'

export default {
    name: 'PrivateLayout',
    components: {
        AxMap,
        DemoMessage,
        MapControls,
        SidePanel,
    },
    computed: {
        ...mapState('locations', ['locations']),
        ...mapState('map', ['mapInstance']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('authentication', ['isDemoUser']),
    },
    watch: {
        $route(to, from) {
            const assetOrLocationRoutePattern = /\/map\/((lost-|low-battery-)?assets|location)/
            const locationRoutePattern = /\/map\/location/

            if (
                !assetOrLocationRoutePattern.test(to.path) ||
                (locationRoutePattern.test(from.path) &&
                    !locationRoutePattern.test(to.path))
            ) {
                this.setFilteredTrackerIds(null)
                this.setFilterParams(null)
                this.setShouldAdjustMapOnFilterChange(false)
            }
        },
    },
    async mounted() {
        const path = this.$route.path.split('/')

        if (
            path.length >= 4 &&
            path[1] === 'map' &&
            path[2] === 'assets' &&
            !isNaN(path[3])
        ) {
            const selectedEntry = this.trackers.find(
                entry => entry.id == path[3]
            )
            if (selectedEntry !== undefined) {
                this.setActiveTrackerOnMap(selectedEntry)
            }
        }

        if (
            path.length >= 4 &&
            path[1] === 'map' &&
            path[2] === 'location' &&
            !isNaN(path[3])
        ) {
            const selectedEntry = this.locations.find(
                entry => entry.id == path[3]
            )
            if (selectedEntry !== undefined) {
                this.setActiveLocationOnMap(selectedEntry)
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.saveBounds(this.mapInstance.getBounds())
        this.setFilteredTrackerIds(null)
        this.setFilterParams(null)
        this.setShouldAdjustMapOnFilterChange(false)
        next()
    },
    methods: {
        ...mapMutations('locations', ['setActiveLocationOnMap']),
        ...mapMutations('map', ['saveBounds']),
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'setFilteredTrackerIds',
            'setFilterParams',
            'setShouldAdjustMapOnFilterChange',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.private-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.25rem 2rem;
    width: 100%;
    height: 100%;
    background-color: #f0f3f5;

    // TEMP:
    padding: 0;

    &__controls {
        margin-bottom: 1.25rem;

        // TEMP:
        position: absolute;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        z-index: 101;
    }

    &__map {
        flex: 1;
        border-radius: 8px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
        overflow: hidden;
        z-index: 0;
    }

    &__panel {
        margin: 1rem;
        border-radius: 8px;
        border-left: $style-border;
        position: absolute;
        top: 5rem;
        right: 2rem;
        transition: height 0.2s;
        max-height: calc(100% - 8.5rem);
        box-shadow: -1px 0 1px rgba(0, 0, 0, 0.04),
            -3px 20px 80px rgba(0, 0, 0, 0.3);

        // TEMP:
        top: 4rem;
        right: 0;
        max-height: calc(100% - 7rem);
    }

    @include respond-to('for-mobile-down') {
        &__controls {
            display: none;
        }

        &__panel {
            top: 0;
            right: 0;
            max-height: calc(100% - 2rem);
        }
    }
}
</style>
