import * as Vue2Leaflet from "vue2-leaflet"
<template>
    <v-feature-group>
        <heatmap-marker
            v-for="(location, key, index) in measurements"
            :key="index"
            :location="location"
        />
    </v-feature-group>
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'
import HeatmapMarker from './HeatmapMarker'

export default {
    name: 'ConnectionHeatmapLayer',
    components: {
        'v-feature-group': Vue2Leaflet.LFeatureGroup,
        HeatmapMarker,
    },
    props: {
        measurements: {
            type: Array,
            required: true,
        },
    },
    computed: {
        allCoordinates() {
            return this.measurements.map(marker => {
                return [marker.lat, marker.lng]
            })
        },
    },
    watch: {
        measurements(measurements) {
            if (measurements) {
                this.$emit('alignMap', this.allCoordinates)
            }
        },
    },
}
</script>

<style scoped></style>
