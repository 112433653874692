<template>
    <l-marker-cluster
        ref="instance"
        :key="'AssetMarkerCluster' + key"
        :options="clusterOptions"
    >
        <slot />
    </l-marker-cluster>
</template>

<script>
import { mapState } from 'vuex'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { DivIcon } from 'leaflet'

export default {
    components: {
        'l-marker-cluster': Vue2LeafletMarkerCluster,
    },
    data() {
        return {
            key: 1,
        }
    },
    computed: {
        ...mapState('map', ['clusteringEnabled']),
        clusterOptions() {
            return {
                zoomToBoundsOnClick: true,
                showCoverageOnHover: false,
                spiderfyOnMaxZoom: true,
                spiderfyDistanceMultiplier: 3.5,
                removeOutsideVisibleBounds: false,
                iconCreateFunction: this.clusterIconCreateFunction,
                ...(!this.clusteringEnabled && { disableClusteringAtZoom: 0 }),
            }
        },
    },
    watch: {
        clusteringEnabled() {
            // Key-changing workaround to force cluster options update on the
            // 'clusteringEnabled' state value chnage
            this.key += 1
        },
    },
    methods: {
        clusterIconCreateFunction(cluster) {
            const children = cluster.getAllChildMarkers()

            const includesWarningIcon = children.some(
                child => child.options.icon.options.warnIconInstance
            )

            const includesErrorIcon = children.some(
                child => child.options.icon.options.errorIconInstance
            )

            let className = 'marker-cluster marker-cluster-'
            if (includesErrorIcon) {
                className += 'error'
            } else if (includesWarningIcon) {
                className += 'warning'
            } else {
                className += 'ok'
            }

            return new DivIcon({
                className,
                iconSize: [10, 10],
            })
        },
    },
}
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.marker-cluster {
    box-shadow: 0 0 0px 2px #fff, 0 0 4px 2px rgba(0, 0, 0, 0.75),
        0 0 2px rgba(0, 0, 0, 0.25) inset;
}

.marker-cluster-error {
    background-color: $color-red;
}

.marker-cluster-ok {
    background-color: $color-green;
}

.marker-cluster-warning {
    background-color: $color-orange;
}
</style>
