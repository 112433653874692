<template>
    <v-circle
        v-if="hasNetworkData"
        :lat-lng="latLng"
        :radius="40"
        :fill="true"
        :fill-color="rssicolor"
        :fill-opacity="0.7"
        :stroke="false"
    />
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'

export default {
    name: 'HeatmapMarker',
    components: {
        'v-circle': Vue2Leaflet.LCircle,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
    },
    computed: {
        latLng() {
            return [this.location.lat, this.location.lng]
        },
        hasNetworkData() {
            return (
                !!this.location?.network_data?.rssi &&
                !!this.location?.network_data?.snr
            )
        },
        rssicolor() {
            const esp =
                this.location.network_data.rssi -
                10 *
                    Math.log(
                        1 + Math.pow(10, -this.location.network_data.snr / 10)
                    )

            if (esp < -110) {
                return '#D32F2F'
            } else if (esp < -100) {
                return '#FDAE61'
            } else {
                return '#41B883'
            }
        },
    },
}
</script>

<style lang="scss">
.heatmap-marker {
    background-color: $color-text;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.7;
}

.heatmap-marker-low {
    background-color: $color-red;
}

.heatmap-marker-medium {
    background-color: $color-orange;
}

.heatmap-marker-high {
    background-color: $color-green;
}
</style>
