<template>
    <div>
        <LMarker
            ref="markerRef"
            :lat-lng="latLng"
            :icon="icon"
            :draggable="isDraggable"
            :options="{ tracker: asset.id, interactive }"
            :visible="visible"
            @click="$emit('markerClicked', [asset])"
            @dragend="updateCoordinates"
        >
            <LTooltip :content="asset.asset_details.name" />
        </LMarker>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { LMarker, LTooltip } from 'vue2-leaflet'
import L from 'leaflet'

const IconWraper = L.Icon.extend({
    options: {
        popupAnchor: [0, 0],
        tooltipAnchor: [0, 0],
        iconInstance: null,
        errorIconInstance: null,
        warnIconInstance: null,
        sensorDataInstance: null,
        iconBorderColor: null,
        iconSize: [0, 0],
        iconAnchor: [0, 0],
    },

    createIcon(oldIcon) {
        const div = document.createElement('div')
        const icon = this.options.iconInstance._createIcon('icon', oldIcon)
        icon.style.marginLeft = '0px'
        icon.style.marginTop = '0px'
        icon.style.borderColor = this.options.iconBorderColor
        div.appendChild(icon)

        if (this.options.errorIconInstance) {
            div.appendChild(this.options.errorIconInstance)
        } else if (this.options.warnIconInstance) {
            div.appendChild(this.options.warnIconInstance)
        }

        if (this.options.sensorDataInstance) {
            div.appendChild(this.options.sensorDataInstance)
        }

        if (this.options.labelInstance) {
            div.appendChild(this.options.labelInstance)
        }

        this._setIconStyles(div, 'icon')
        return div
    },

    createShadow(oldIcon) {
        return this.options.iconInstance._createIcon('shadow', oldIcon)
    },
})

export default {
    name: 'AssetMarker',
    components: {
        LMarker,
        LTooltip,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        showWarnIcon: {
            type: Boolean,
            default: false,
        },
        showErrorIcon: {
            type: Boolean,
            default: false,
        },
        informationPills: {
            type: Array,
            default: () => [],
        },
        onOff: {
            type: Boolean,
            default: null,
        },
        iconUrl: {
            type: String,
            default: null,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState('map', ['showLabels']),
        latLng() {
            return [
                this.asset.asset_details.position.latitude,
                this.asset.asset_details.position.longitude,
            ]
        },
        isDraggable() {
            return (
                this.$route.name === 'editAsset' &&
                this.$route.params.id == this.asset.id &&
                this.asset.asset_details.static
            )
        },
        icon() {
            let errorIconUrl
            let warnIconUrl

            if (this.showErrorIcon) {
                errorIconUrl = require('../assets/icons/outline-error.svg')
            } else if (this.showWarnIcon) {
                warnIconUrl = require('../assets/icons/fill-warning.svg')
            }

            if (
                this.asset.tracker_status &&
                this.asset.tracker_status.battery_low
            ) {
                errorIconUrl = require('../assets/icons/charging-battery-low-1.svg')
            }

            if (this.asset?.last_gps_measurement?.is_outside) {
                errorIconUrl = require('../assets/icons/cursor-move-target-right.svg')
            }

            return this.createIcon(
                this.iconUrl || this.asset.asset_details.asset_type_marker,
                errorIconUrl,
                warnIconUrl
            )
        },
    },
    methods: {
        ...mapMutations('tracker', ['updateAsset']),
        updateCoordinates(marker) {
            this.updateAsset({
                id: this.asset.asset_details.id,
                position: {
                    latitude: marker.target._latlng.lat,
                    longitude: marker.target._latlng.lng,
                },
            })
        },
        createIcon(iconUrl, errorIconUrl, warnIconUrl) {
            if (!this.asset?.asset_details?.asset_type) {
                console.error('Asset has no asset-type information', this.asset)
                return
            }

            const shadowUrl = require('../assets/twemoji/shadow.svg')

            const iconWidthAndHeight = 64
            const iconElevation = 20
            const shadowWidthAndHeight = 90

            let className = 'asset-marker'

            if (this.isSelected) {
                className += ' selected-marker'
            }

            const icon = L.icon({
                iconUrl,
                iconSize: [iconWidthAndHeight, iconWidthAndHeight],
                iconAnchor: [
                    iconWidthAndHeight / 2,
                    iconWidthAndHeight + iconElevation,
                ],
                shadowUrl,
                shadowSize: [shadowWidthAndHeight, shadowWidthAndHeight],
                shadowAnchor: [
                    shadowWidthAndHeight / 2,
                    shadowWidthAndHeight / 2,
                ],
                tooltipAnchor: [
                    iconWidthAndHeight / 2,
                    -iconElevation - iconWidthAndHeight / 2,
                ],
                className,
            })

            let errorIcon
            if (errorIconUrl) {
                errorIcon = document.createElement('img')
                errorIcon.src = errorIconUrl
                errorIcon.className = 'error-icon'
            }

            let warnIcon
            if (warnIconUrl) {
                warnIcon = document.createElement('img')
                warnIcon.src = warnIconUrl
                warnIcon.className = 'warn-icon'
            }

            let label
            if (this.showLabels) {
                label = document.createElement('span')
                label.className = 'icon-label'
                label.innerHTML = this.asset.asset_details.name
                const assetId = this.asset.asset_details.identification

                if (assetId) {
                    label.innerHTML += '<br>' + assetId
                }
            }

            let sensorDataInstances = []
            for (const n in this.informationPills) {
                const pill = this.informationPills[n]
                if (pill?.text !== undefined) {
                    const pill_holder = document.createElement('div')
                    pill_holder.className = pill?.className
                    pill_holder.innerText = pill?.text
                    sensorDataInstances.push(pill_holder)
                }
            }

            if (this.onOff !== null) {
                const element = document.createElement('div')
                element.className =
                    'sensor-data sensor-data-running sensor-data-on-off'
                if (!this.onOff) {
                    element.className += ' negative'
                }
                sensorDataInstances.push(element)
            }

            let sensorDataHolder
            if (sensorDataInstances.length > 0) {
                sensorDataHolder = document.createElement('div')
                sensorDataHolder.className = 'sensor-data-holder'

                sensorDataInstances.forEach(sensorData => {
                    sensorDataHolder.appendChild(sensorData)
                })
            }

            return new IconWraper({
                iconInstance: icon,
                errorIconInstance: errorIcon,
                warnIconInstance: warnIcon,
                sensorDataInstance: sensorDataHolder,
                labelInstance: label,
                iconBorderColor: this.asset.asset_details.color,
                iconSize: [iconWidthAndHeight, iconWidthAndHeight],
                iconAnchor: [
                    iconWidthAndHeight / 2,
                    iconWidthAndHeight + iconElevation,
                ],
                tooltipAnchor: [
                    iconWidthAndHeight / 2,
                    -iconElevation - iconWidthAndHeight / 2,
                ],
            })
        },
    },
}
</script>

<style lang="scss">
.sensor-data-on-off {
    margin-left: 50px;
}

.sensor-data-running {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-green;

    &.negative {
        background-color: $color-red;
    }
}
</style>
