<template>
    <l-feature-group>
        <l-polyline
            :lat-lngs="coordinates"
            :fill="false"
            :color="getActiveLayer.traceBorderColor || 'transparent'"
            :weight="4"
            dash-array="7 7"
            :opacity="0.5"
        />

        <l-polyline
            ref="polyline"
            :lat-lngs="coordinates"
            :fill="false"
            :color="getActiveLayer.traceColor"
            :weight="2"
            dash-array="7 7"
        />
    </l-feature-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { LFeatureGroup, LPolyline } from 'vue2-leaflet'

export default {
    name: 'TrackerTraceLayer',
    components: {
        LFeatureGroup,
        LPolyline,
    },
    props: {
        coordinates: {
            type: Array,
            default: null,
        },
    },
    computed: {
        ...mapGetters('map', ['getActiveLayer']),
    },
}
</script>
