<template>
    <l-marker
        :icon="icon"
        :lat-lng="latLng"
        @click="$emit('markerClicked', location)"
    >
        <l-tooltip>
            <b>{{ location.index }}</b> {{ timestampFormatted }}
        </l-tooltip>
    </l-marker>
</template>

<script>
import { LMarker, LTooltip } from 'vue2-leaflet'
import L from 'leaflet'
import moment from 'moment'

const IconWraper = L.Icon.extend({
    createIcon() {
        const div = document.createElement('div')
        div.className = 'history-marker'
        this._setIconStyles(div, 'icon')
        return div
    },
})

export default {
    name: 'HistoryMarker',
    components: {
        LMarker,
        LTooltip,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        latLng() {
            return [this.location.lat, this.location.lng]
        },
        icon() {
            const size = 8
            const center = size / 2

            let className = 'history-marker'
            if (this.isSelected) {
                className += ' history-marker--selected'
            }

            return new IconWraper({
                className,
                iconSize: [size, size],
                iconAnchor: [center, center],
                tooltipAnchor: [center, 0],
            })
        },
        timestampFormatted() {
            return moment(this.location.timestamp).format('DD.MM.YYYY HH:mm')
        },
    },
}
</script>

<style lang="scss" scoped>
b {
    margin-right: 0.5em;
}
</style>

<style lang="scss">
.history-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);

    &--selected {
        background-color: rgb(239, 38, 85);
        z-index: 2000 !important;
    }
}
</style>
