<template>
    <l-feature-group>
        <history-marker
            v-for="(location, index) in assetHistory"
            :key="index"
            :location="location"
            :is-selected="selectedHistoryLocationID == location.id"
            @markerClicked="setSelectedHistoryLocationID($event.id)"
        />

        <tracker-trace-layer :coordinates="assetHistoryCoordinates" />
    </l-feature-group>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'

import HistoryMarker from './HistoryMarker'
import TrackerTraceLayer from './TrackerTraceLayer'

export default {
    name: 'HistoryLayer',
    components: {
        HistoryMarker,
        LFeatureGroup,
        TrackerTraceLayer,
    },
    computed: {
        ...mapGetters('map', ['getActiveLayer']),
        ...mapGetters('tracker', [
            'assetHistoryData',
            'selectedHistoryLocationID',
        ]),
        assetHistory() {
            if (!this.assetHistoryData) {
                return []
            }

            return this.assetHistoryData.filter(
                item =>
                    (item.kind === 'gps-fix' || item.kind === 'indoor') &&
                    item.lat &&
                    item.lng &&
                    item.is_position_accurate
            )
        },
        assetHistoryCoordinates() {
            return this.assetHistory.map(item => [item.lat, item.lng])
        },
        selectedAssetHistoryLocation() {
            return this.assetHistory.find(
                item => item.id == this.selectedHistoryLocationID
            )
        },
        isTraceHighlighted() {
            return !!this.getActiveLayer.traceBorderColor
        },
    },
    watch: {
        assetHistory(assetHistory) {
            if (assetHistory) {
                this.$emit('alignMap', this.assetHistoryCoordinates)
            }
        },
        selectedAssetHistoryLocation(location) {
            if (location) {
                this.$emit('alignMap', [[location.lat, location.lng]])
            }
        },
    },
    methods: {
        ...mapMutations('tracker', ['setSelectedHistoryLocationID']),
    },
}
</script>
