<template>
    <v-feature-group ref="createLocation"> </v-feature-group>
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'
import 'leaflet-editable'
import { mapGetters, mapMutations } from 'vuex'
import L from 'leaflet'
import LocationsHelper from '../mixins/LocationsHelper'

export default {
    name: 'CreateLocationLayer',
    components: {
        'v-feature-group': Vue2Leaflet.LFeatureGroup,
    },
    mixins: [LocationsHelper],
    props: {},
    data: function() {
        return {
            polygon: null,
        }
    },
    computed: {
        ...mapGetters('createLocation', [
            'getInitLocationPolygon',
            'getResetLocationPolygon',
            'getLocationPolygon',
        ]),
    },
    watch: {
        getResetLocationPolygon(reset) {
            if (this.polygon && reset) {
                this.removeCurrentPolygon()
                this.setLocationPolygon([])
                this.setResetLocationPolygonDone()
                this.startNewPolygon()
            }
        },
        getInitLocationPolygon(geofence) {
            if (geofence) {
                this.removeCurrentPolygon()

                this.polygon = L.polygon(
                    this.getLatLngsFromLocationGeofence(geofence)
                )
                this.polygon.addTo(this.$refs.createLocation.mapObject)
                this.polygon.enableEdit()
                this.setLocationPolygon(this.polygon.toGeoJSON())

                this.polygon.on('editable:editing', () => {
                    this.setLocationPolygon(this.polygon.toGeoJSON())
                })

                this.setLocationPolygonInitialized()
            }
        },
    },
    beforeDestroy() {
        if (this.polygon) {
            this.removeCurrentPolygon()
            this.setLocationPolygon([])
        }
    },
    mounted() {
        this.startNewPolygon()
    },
    methods: {
        ...mapMutations('createLocation', [
            'setLocationPolygon',
            'setLocationPolygonDrawing',
            'setResetLocationPolygonDone',
            'setLocationPolygonInitialized',
        ]),
        startNewPolygon() {
            this.polygon = this.$refs.createLocation.parentContainer.mapObject.editTools.startPolygon()
            this.polygon.editor.enable()

            this.polygon.on('editable:editing', () => {
                this.setLocationPolygon(this.polygon.toGeoJSON())
            })

            this.polygon.on('editable:drawing:end', () => {
                this.setLocationPolygonDrawing(false)
            })

            this.setLocationPolygonDrawing(true)
        },
        removeCurrentPolygon() {
            if (this.polygon) {
                this.polygon.disableEdit()
                this.polygon.remove()
            }
        },
    },
}
</script>
