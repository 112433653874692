<template>
    <l-marker-cluster
        ref="instance"
        :key="'AssetMarkerCluster' + key"
        :options="clusterOptions"
    >
        <slot />
    </l-marker-cluster>
</template>

<script>
import { mapState } from 'vuex'
import { DivIcon, Point } from 'leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'

export default {
    components: {
        LMarkerCluster,
    },
    data() {
        return {
            key: 1,
        }
    },
    computed: {
        ...mapState('map', ['clusteringEnabled']),
        clusterOptions() {
            return {
                zoomToBoundsOnClick: true,
                showCoverageOnHover: false,
                spiderfyOnMaxZoom: true,
                spiderfyDistanceMultiplier: 3.5,
                removeOutsideVisibleBounds: false,
                iconCreateFunction: this.clusterIconCreateFunction,
                ...(!this.clusteringEnabled && { disableClusteringAtZoom: 0 }),
                chunkedLoading: true,
            }
        },
    },
    watch: {
        clusteringEnabled() {
            // Key-changing workaround to force cluster options update on the
            // 'clusteringEnabled' state value chnage
            this.key += 1
        },
    },
    methods: {
        clusterIconCreateFunction(cluster) {
            const childCount = cluster.getChildCount()
            let className = 'marker-cluster marker-cluster-'
            if (childCount < 10) {
                className += 'small'
            } else if (childCount < 100) {
                className += 'medium'
            } else {
                className += 'large'
            }

            const warnIcon = require('../assets/icons/outline-error.svg')
            const includesWarnIcon = cluster
                .getAllChildMarkers()
                .some(
                    item =>
                        item.options.icon.options.errorIconInstance ||
                        item.options.icon.options.warnIconInstance
                )

            return new DivIcon({
                className,
                html: includesWarnIcon
                    ? `<div><span>${childCount}</span><img class="cluster-warn-icon" src="${warnIcon}"/></div>`
                    : `<div><span>${childCount}</span></div>`,
                iconSize: new Point(40, 40),
            })
        },
    },
}
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.cluster-warn-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    top: -2px;
    right: -2px;

    border-radius: 50%;
    background-color: white;
}
</style>
