<template>
    <asset-marker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :show-error-icon="showErrorIcon"
        :show-warn-icon="showWarnIcon"
        :information-pills="informationPills"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './../../AssetMarker'
import { fillLevelThresholds } from './dynamicBinSettings'

export default {
    name: 'DynamicBinMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fillLevel() {
            return this.asset?.asset_details?.sensor_data?.fill_level?.value
        },
        showErrorIcon() {
            return (
                this.fillLevel >= fillLevelThresholds.high ||
                this.asset?.tracker_status?.battery_low
            )
        },
        showWarnIcon() {
            return (
                this.asset.asset_details?.alert ||
                this.fillLevel > fillLevelThresholds.low
            )
        },
        fullnessClass() {
            return this.fillLevel <= fillLevelThresholds.low
                ? ' sensor-data-bin-empty'
                : this.fillLevel >= fillLevelThresholds.high
                ? ' sensor-data-bin-full'
                : ' sensor-data-bin-medium'
        },
        informationPills() {
            let classes = 'sensor-data sensor-data-fill-level'
            classes += this.fullnessClass
            let pills = [
                {
                    text: Math.round(this.fillLevel * 100) + '%',
                    className: classes,
                },
            ]

            return pills
        },
    },
}
</script>
