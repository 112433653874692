<template>
    <div class="demo-message">
        <div class="l-padded">
            <p>
                <strong>{{ $t(demoType + '-title') }}</strong>
            </p>

            <p>
                {{ $t('description') }}
            </p>

            <p>
                {{ $t('contact') }}<br />
                <a class="t-underline" href="https://ax-track.ch/"
                    >ax-track.ch</a
                >,
                <a class="t-underline" href="mailto:info@adnexo.ch"
                    >info@adnexo.ch</a
                >
                {{ $t('or') }} +41 44 500 21 20
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'DemoMessage',
    computed: {
        ...mapGetters('demo', ['demoType']),
    },
}
</script>

<i18n>
{
    "en": {
        "ax-city-title": "Smart City Platform made in Switzerland",
        "ax-collect-title": "Waste Management Platform made in Switzerland",
        "ax-sports-title": "Track our demo bicycle race!",
        "ax-track-title": "Tracking Platform made in Switzerland",
        "contact": "If you are interested, reach out to us at",
        "description": "You are experiencing a demo instance of our tracking platform.",
        "or": "or"
    },
    "de": {
        "ax-city-title": "Smart City Platform made in Switzerland",
        "ax-collect-title": "Waste Management Platform made in Switzerland",
        "ax-sports-title": "Verfolgen Sie unser Demo-Fahrradrennen!",
        "ax-track-title": "Tracking Platform made in Switzerland",
        "contact": "Wenn Sie interessiert sind, kontaktieren Sie uns unter",
        "description": "Sie befinden sich in einer Demo-Instanz unserer Tracking-Plattform.",
        "or": "oder"
    },
    "it": {
        "ax-city-title": "Smart City Platform made in Switzerland",
        "ax-collect-title": "Waste Management Platform made in Switzerland",
        "ax-sports-title": "Pista la nostra gara ciclistica dimostrativa!",
        "ax-track-title": "Tracking Platform made in Switzerland",
        "contact": "Se sei interessato, contattaci al seguente indirizzo",
        "description": "Stai sperimentando un'istanza dimostrativa della nostra piattaforma di monitoraggio.",
        "or": "ovvero"
    }
}
</i18n>

<style lang="scss" scoped>
.demo-message {
    left: 30px;
    width: 450px;
    background-color: #fff;

    p + p {
        padding-top: 1rem;
    }

    @include respond-to('for-mobile-down') {
        display: none;
    }
}
</style>
