<template>
    <asset-marker
        :asset="asset"
        :is-selected="isSelected"
        :interactive="interactive"
        :show-error-icon="false"
        :show-warn-icon="showWarnIcon"
        :information-pills="[]"
        :on-off="onOff"
        :visible="visible"
        @markerClicked="$emit('markerClicked', $event)"
    />
</template>

<script>
import AssetMarker from './AssetMarker'

export default {
    name: 'MachineMarker',
    components: {
        AssetMarker,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showWarnIcon() {
            return (
                this.asset.asset_details?.alert ||
                !!this.asset?.tracker_status?.battery_low ||
                this.asset?.last_gps_measurement?.is_outside
            )
        },
        onOff() {
            if (
                this.asset?.asset_details?.sensor_data?.d1?.value === undefined
            ) {
                return undefined
            }
            return !!this.asset?.asset_details?.sensor_data?.d1?.value
        },
    },
}
</script>
